<template>
  <div style="background: #FFFFFF; padding: 20px">
    <div style="text-align: right; margin-bottom: 20px">
      <el-button size="small" @click="$router.back()" style="float: left">返回</el-button>
      <el-button size="small" type="success" @click="showAddDialog">添加字段</el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="fields"
      border
      style="width: 100%">
      <el-table-column
        prop="fieldCode"
        label="字段编码">
      </el-table-column>
      <el-table-column
        prop="fieldType"
        label="字段类型">
      </el-table-column>
      <el-table-column
        prop="fieldDisplay"
        label="显示文本">
      </el-table-column>
      <el-table-column
        prop="fieldRemark"
        label="字段备注">
      </el-table-column>
      <el-table-column
        prop="fieldSort"
        label="字段排序">
      </el-table-column>
      <el-table-column
        prop="createDate"
        label="创建时间">
      </el-table-column>
      <el-table-column
        width="160"
        align="center"
        label="操作">
        <template #default="{ row }">
          <el-button size="mini" type="primary" @click="showEditDialog(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteField(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="addDialogVisible" title="添加字段" @close="resetForm" width="500px">
      <el-form v-loading="addLoading" size="small" :model="addForm" :rules="formRules" ref="addForm" label-position="right" label-width="80px">
        <el-form-item label="字段编码" prop="fieldCode">
          <el-input v-model="addForm.fieldCode"></el-input>
        </el-form-item>
        <el-form-item label="字段类型" prop="fieldType">
          <el-select v-model="addForm.fieldType" placeholder="请选择字段类型">
            <el-option label="文本" value="text"></el-option>
            <el-option label="数值" value="number"></el-option>
            <el-option label="位置" value="location"></el-option>
            <el-option label="枚举" value="enum"></el-option>
            <el-option label="引用" value="table"></el-option>
            <el-option label="日期" value="date"></el-option>
            <el-option label="图片" value="image"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="枚举键值" prop="link" v-if="addForm.fieldType === 'enum'">
          <el-input v-model="addForm.link"></el-input>
        </el-form-item>
        <el-form-item label="单选引用" prop="link" v-if="addForm.fieldType === 'table'">
          <el-select v-model="addForm.link" placeholder="请选择关联数据表">
            <el-option label="客户" value="customer"></el-option>
            <el-option label="员工" value="staff"></el-option>
            <el-option label="管理员" value="staff_manager"></el-option>
            <el-option v-for="table in tables" :label="table.expandTableName" :value="table.alias" :key="table.alias"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="引用字段" prop="linkField" v-if="addForm.fieldType === 'table'">
          <el-input v-model="addForm.linkField" placeholder="引用字段"></el-input>
        </el-form-item>
        <el-form-item label="显示文本" prop="fieldDisplay">
          <el-input v-model="addForm.fieldDisplay"></el-input>
        </el-form-item>
        <el-form-item label="字段排序" prop="fieldSort">
          <el-input type="number" v-model="addForm.fieldSort"></el-input>
        </el-form-item>
        <el-form-item label="字段备注" prop="fieldRemark">
          <el-input type="textarea" v-model="addForm.fieldRemark"></el-input>
        </el-form-item>
        <el-form-item label="是否必填" prop="isRequired">
          <el-radio-group v-model="addForm.isRequired">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="必填警告" prop="requiredWarningText">
          <el-input v-model="addForm.requiredWarningText"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="addDialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submitForm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="editDialogVisible" title="编辑字段" @close="resetForm" width="500px">
      <el-form v-loading="editLoading" size="small" :model="editForm" :rules="formRules" ref="editForm" label-position="right" label-width="80px">
        <el-form-item label="字段编码" prop="fieldCode">
          <el-input v-model="editForm.fieldCode"></el-input>
        </el-form-item>
        <el-form-item label="字段类型" prop="fieldType">
          <el-select v-model="editForm.fieldType" placeholder="请选择字段类型">
            <el-option label="文本" value="text"></el-option>
            <el-option label="数值" value="number"></el-option>
            <el-option label="位置" value="location"></el-option>
            <el-option label="枚举" value="enum"></el-option>
            <el-option label="引用" value="table"></el-option>
            <el-option label="日期" value="date"></el-option>
            <el-option label="图片" value="image"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="枚举键值" prop="link" v-if="editForm.fieldType === 'enum'">
          <el-input v-model="editForm.link"></el-input>
        </el-form-item>
        <el-form-item label="单选引用" prop="link" v-if="editForm.fieldType === 'table'">
          <el-select v-model="editForm.link" placeholder="请选择关联数据表">
            <el-option label="客户" value="customer"></el-option>
            <el-option label="员工" value="staff"></el-option>
            <el-option label="管理员" value="staff_manager"></el-option>
            <el-option v-for="table in tables" :label="table.expandTableName" :value="table.alias" :key="table.alias"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="引用字段" prop="linkField" v-if="editForm.fieldType === 'table'">
          <el-input v-model="editForm.linkField" placeholder="引用字段"></el-input>
        </el-form-item>
        <el-form-item label="显示文本" prop="fieldDisplay">
          <el-input v-model="editForm.fieldDisplay"></el-input>
        </el-form-item>
        <el-form-item label="字段排序" prop="fieldSort">
          <el-input type="number" v-model="editForm.fieldSort"></el-input>
        </el-form-item>
        <el-form-item label="字段备注" prop="fieldRemark">
          <el-input type="textarea" v-model="editForm.fieldRemark"></el-input>
        </el-form-item>
        <el-form-item label="是否必填" prop="isRequired">
          <el-radio-group v-model="editForm.isRequired">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="必填警告" prop="requiredWarningText">
          <el-input v-model="editForm.requiredWarningText"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="editDialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submitUpdate">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getFields, addField, updateField, deleteField } from '@/api/expandTableFields'
import {expandTablesList} from "@/api/expandTable";

export default {
  name: 'expandTableFields',
  data () {
    return {
      loading: false,
      addLoading: false,
      editLoading: false,
      fields: [],
      tableId: '',
      addDialogVisible: false,
      editDialogVisible: false,
      addForm: {
        tableId: '',
        fieldCode: '',
        fieldType: '',
        fieldDisplay: '',
        fieldSort: null,
        fieldRemark: '',
        isRequired: '0',
        requiredWarningText: ''
      },
      editForm: {
        id: '',
        fieldCode: '',
        fieldType: '',
        fieldDisplay: '',
        fieldSort: null,
        fieldRemark: '',
        isRequired: '0',
        requiredWarningText: ''
      },
      formRules: {
        fieldCode: [
          { required: true, message: '请输入字段编码', trigger: 'blur' }
        ],
        fieldType: [
          { required: true, message: '请输入字段类型', trigger: 'blur' }
        ],
        fieldDisplay: [
          { required: true, message: '请输入字段显示文本', trigger: 'blur' }
        ],
        fieldSort: [
          { required: true, message: '请输入字段排序', trigger: 'blur' }
        ],
        link: [
          { required: true, message: '请输入键值对信息', trigger: 'change' }
        ],
        linkField: [
          { required: true, message: '请输入字段', trigger: 'change' }
        ]
      },
      tables: []
    }
  },
  mounted () {
    this.tableId = this.$route.params.id
    this.getFields()
    this.expandTablesList()
  },
  methods: {
    async expandTablesList () {
      const res = await expandTablesList({})
      this.tables = res.data
    },
    async getFields () {
      this.loading = true
      const res = await getFields({ alias: this.tableId })
      this.loading = false
      this.fields = res.data
    },
    showAddDialog () {
      this.addForm.alias = this.tableId
      this.addDialogVisible = true
    },
    showEditDialog (row) {
      this.editDialogVisible = true
      this.editForm = JSON.parse(JSON.stringify(row))
    },
    resetForm () {
      if (this.$refs.addForm) this.$refs.addForm.resetFields()
      if (this.$refs.editForm) this.$refs.editForm.resetFields()
    },
    async submitForm () {
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          this.addLoading = true
          const res = await addField(this.addForm)
          this.addLoading = false
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.addDialogVisible = false
            this.getFields()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async submitUpdate () {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          this.editLoading = true
          const res = await updateField(this.editForm)
          this.editLoading = false
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.editDialogVisible = false
            this.getFields()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async deleteField (id) {
      this.loading = true
      const res = await deleteField(id)
      this.loading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.getFields()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
